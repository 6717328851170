import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useMemo, useEffect, useState, useCallback } from "react";
import { cloneDeep, get, isEqual } from "lodash";
import { Card, CardBody, Container, Row, Col, Spinner } from "reactstrap";
import { Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import { BREAKPOINTS } from "../../constants/appConstants";
import GoogleLogo from "../../assets/images/GoogleLogo.svg";
import LoginImage from "../../assets/images/LoginImage.png";
import { saveTokens, selectUserAndToken } from "../../store/slices/authSlice";
import { useAppSelector } from "../../store/hooks";
import { HOME_PAGE } from "../../constants/urls";
import useOAuth from "../../hooks/auth/useOAuth";

/**
 * Login page
 */
const Login = () => {
  // Page title
  const { intlTitle } = useTitle();
  const [loading, setLoading] = useState(false);
  const currentPath = useMemo(
    () => `${window.location.origin}${window.location.pathname}`,
    []
  );
  const { login, user: googleUser } = useOAuth({
    redirectSignIn: currentPath,
    redirectSignOut: currentPath,
  });
  const [user, setUser] = useState<any>(cloneDeep(googleUser));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isEqual(user, googleUser)) {
        setUser(cloneDeep(googleUser));
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [googleUser]);

  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.LG });

  const dispatch = useDispatch();

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  };

  const { processing, user: authUser } = useAppSelector(selectUserAndToken);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (user) {
        setLoading(false);
        dispatch(
          saveTokens({
            accessToken: get(
              user,
              "signInUserSession.accessToken.jwtToken",
              ""
            ),
            idToken: get(user, "signInUserSession.idToken.jwtToken", ""),
            refreshToken: get(
              user,
              "signInUserSession.refreshToken.jwtToken",
              ""
            ),
          })
        );
      }
    }, 100);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navigate = useNavigate();

  const checkAuth = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      if (authUser && session) {
        navigate(HOME_PAGE);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [authUser, navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => checkAuth(), 100);

    return () => clearTimeout(timeoutId);
  }, [checkAuth]);

  const isLoading = loading || processing;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle("login.title")}</title>
      </Helmet>
      <Container
        fluid
        className="d-flex align-items-center justify-content-center m-auto"
      >
        <Card className="vtl-login-card">
          <CardBody className="p-0">
            <Row className="gx-0 gy-5 flex-fill">
              <Col lg className="d-flex align-items-center">
                <div className="px-3 px-lg-s2z-50 w-100">
                  <div className="text-center pb-3 pb-lg-s2z-50">
                    <Link to={HOME_PAGE}>
                      <Image src="/images/s2zLogoTMCrop.svg" width={126} />
                    </Link>
                    {isLoading && (
                      <div className="mt-5">
                        <Spinner />
                      </div>
                    )}
                    {!isLoading && (
                      <Button
                        variant="outline-secondary"
                        className="w-100 d-flex align-items-center justify-content-center mt-5"
                        onClick={handleLogin}
                      >
                        <span className="me-2 pe-1">
                          <Image src={GoogleLogo} />
                        </span>
                        <FormattedMessage id="login.google" />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="auto">
                <Image
                  width={isMobile ? "100%" : 470}
                  height={isMobile ? 250 : "inherit"}
                  fluid={!isMobile}
                  src={LoginImage}
                  style={{ objectFit: "cover" }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </HelmetProvider>
  );
};

export default Login;
